import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { css } from 'styled-components'
import { Helmet } from 'react-helmet'

import { GatsbySeo } from 'gatsby-plugin-next-seo'

import {
  FAQSection,
  TheProcessSection,
  TypesOfFinishingSection,
  WhyUsSection,
  RecentWorkSection
} from '../components/Section'

import {
  fontSizes,
  space,
  mediaQueries,
  letterSpacings,
  colors,
} from '../utils/tokens'

import Container from '../components/Container'
import { SectionSelector } from '../components/SectionSelector'
import { HeroSelector } from '../components/HeroSelector'
import { faqPageSchemaGenerator } from '../utils/faqPageSchemaGenerator'
import { MainLayout } from '../components/MainLayout'
import siteConfig from '../utils/siteConfig'

const ServiceTemplate = (props) => {
  const { data } = props
  const {
    hero,
    whyUs,
    theProcess,
    typesOfFinishing,
    materials,
    frequentlyAskedQuestions,
    typeform,
    metaDescription,
    metaTitle,
    metaImage,
    name,
    featuredCompanies,
    slug,
    gallery,
    sections,
    fields
  } = data.contentfulService

  const postNode = {
    title: metaTitle || `${name} - Service | NYC | PrintAWorld`,
    metaDescription: metaDescription,
  }

  const sanitizedFaqs =
    frequentlyAskedQuestions?.length > 0 &&
    frequentlyAskedQuestions.map(faq => {
      return {
        question: faq.question,
        answer: faq.answer && faq.answer.childMarkdownRemark.html,
      }
    })

  const faqSchema = faqPageSchemaGenerator(sanitizedFaqs)

  const ogImages = metaImage && [
    {
      url: `https:${metaImage.file?.url}`,
      alt: metaImage.title,
      width: 1200,
      height: 627,
    },
  ]

  return (
    <MainLayout headerTheme={hero?.theme === `dark` ? `dark` : `light`}>
      <Helmet>
        {faqSchema && (
          <script type="application/ld+json">
            {JSON.stringify(faqSchema)}
          </script>
        )}
      </Helmet>
      <GatsbySeo
        title={metaTitle || `${name} Service | PrintAWorld`}
        description={metaDescription?.internal?.content}
        openGraph={{
          url: `${siteConfig.siteUrl}${fields.path}`,
          images: ogImages,
          title: metaTitle || `${name} Service | PrintAWorld`,
        }}
      />
      <main>
        {hero && (
          <HeroSelector
            hero={hero}
            typeform={typeform}
            featuredCompanies={featuredCompanies}
          />
        )}
        {sections && <SectionSelector sections={sections} />}
        {whyUs && <WhyUsSection cards={whyUs} />}
        {gallery?.images && (
          <RecentWorkSection
            gallery={gallery}
            serviceName={name}
            css={css({
              backgroundColor: colors.white,
            })}
          />
        )}
        {theProcess && <TheProcessSection cards={theProcess} />}
        {typesOfFinishing && (
          <TypesOfFinishingSection sections={typesOfFinishing} />
        )}
        {materials && (
          <section
            css={css({
              padding: `${space[5]}px ${space[4]}px`,
              backgroundColor: colors.lightGray,
              [mediaQueries.lg]: {
                padding: `${space[6]}px ${space[5]}px`,
              },
            })}
          >
            <Container>
              <h3
                css={css({
                  textTransform: `uppercase`,
                  fontWeight: `bold`,
                  fontSize: fontSizes[2],
                  marginBottom: `${space[3]}px`,
                })}
              >
                Materials
              </h3>
              <div
                css={css({
                  [mediaQueries.lg]: {
                    display: `grid`,
                    gridGap: `${space[4]}px`,
                    gridTemplateColumns: `repeat(5, 1fr)`,
                  },
                })}
              >
                {materials.map((material, id) => {
                  return (
                    <section
                      key={id}
                      css={css({
                        padding: `${space[4]}px 0`,
                      })}
                    >
                      <div
                        css={css({
                          display: `grid`,
                          gridGap: `${space[3]}px`,
                        })}
                      >
                        {material.coverPhoto && (
                          <Img
                            fluid={material.coverPhoto.fluid}
                            title={material.coverPhoto.title}
                            css={css({
                              width: `100%`,
                              [mediaQueries.lg]: {
                                marginRight: `${space[4]}px`,
                              },
                            })}
                          />
                        )}
                        <div>
                          <h4
                            css={css({
                              fontWeight: `bold`,
                              fontSize: fontSizes[4],
                              marginBottom: `${space[3]}px`,
                            })}
                          >
                            {material.name}
                          </h4>
                          {material.description && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  material.description.childMarkdownRemark.html,
                              }}
                              css={css({
                                letterSpacing: letterSpacings.normal,
                                color: `${colors.black}a1`,
                                p: {
                                  marginBottom: `${space[3]}px`,
                                },
                              })}
                            />
                          )}
                        </div>
                      </div>
                    </section>
                  )
                })}
              </div>
            </Container>
          </section>
        )}
        {frequentlyAskedQuestions && (
          <FAQSection faqs={frequentlyAskedQuestions} />
        )}
      </main>
    </MainLayout>
  )
}

export default ServiceTemplate

export const serviceTemplateQuery = graphql`
  query($slug: String!) {
    contentfulService(slug: { eq: $slug }) {
      name
      id
      slug
      typeform
      fields {
        path
      }
      metaDescription {
        internal {
          content
        }
      }
      metaTitle
      metaImage {
        title
        fixed(width: 1200, height: 627, quality: 80) {
          src
        }
        file {
          url
        }
      }
      hero {
        ... on Node {
          __typename
          ... on ContentfulHeroWithBlock {
            ...HeroWithBlock
          }
          ... on ContentfulHeroTextOnly {
            ...HeroTextOnly
          }
          ... on ContentfulHero {
            ...HeroOld
          }
        }
      }
      sections {
        ... on Node {
          ...SectionsFragment
        }
      }
      featuredCompanies {
        title
        file {
          url
        }
      }
      gallery {
        images {
          ...RecentWorkImages
        }
      }
      typesOfFinishing {
        title
        subtitle
        body {
          childMarkdownRemark {
            html
          }
        }
        cards {
          ... on ContentfulCard {
            title
            media {
              title
              fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              fixed(width: 300) {
                ...GatsbyContentfulFixed_withWebp_noBase64
              }
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      materials {
        name
        coverPhoto {
          title
          fluid(maxHeight: 1200, maxWidth: 1200) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      frequentlyAskedQuestions {
        question
        id
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`  